<template>
    <div class="coupon">
        <van-tabs v-model="qrystatus" 
                @change="changeTab" 
                color="#E69F12"
                title-active-color="#E69F12"
                sticky
                swipeable>
            <van-tab title="未领用" name="0" :badge="unuse">
                <div class="treat_main_list" v-if="list1.length > 0">
                    <van-list
                        v-model="loading1"
                        :finished="finished1"
                        @load="onLoad"
                        :offset="30"
                        :immediate-check="false"
                        
                        finished-text="加载完成">
                        <div :class="{'content':true,'contentun' : item.isexpire == 1}" style="margin-bottom: 10px;" v-for="item in list1" >
                            <div class="top">
                                    <div class="left">{{ item.cardname }}</div>
                                    <div class="right">有效期至{{ item.effectivedate }}</div>
                                </div>
                                <div class="middle">
                                    <div class="title title1">权益</div>
                                    <div class="title">价值</div>
                                    <div class="title">次数</div>
                                </div>
                                <div class="middle1" v-for="child in item.couponlist">
                                    <div class="text textwidth">{{ child.couponname }}</div>
                                    <div class="text">￥{{ child.money }}</div>
                                    <div class="text">{{ child.totalnum }}（剩{{ child.surplusnum }}）</div>
                                </div>
                                <div class="tips" v-if="item.remark">
                                    <div class="title">注意事项：</div>
                                    <div class="info" style="white-space: break-spaces;">{{ item.remark }}</div>
                                </div>
                                <div class="btn" v-if="item.isexpire != 1">
                                    <van-button type="default" size="small" v-if="item.usetype != '1'" @click="receive(item)">领用</van-button>
                                    <van-button type="default" size="small" v-if="item.usetype != '0'" @click="give(item)">赠送</van-button>
                                </div>
                                <div class="ribbon">{{item.isexpire == 1 ? '已过期' : '未领用'}}</div>
                        </div>
                    </van-list>
                </div>
                <div class="nodata" v-else>暂无卡包数据</div>
            </van-tab>
            <van-tab title="已领用" name="1" :badge="used">
                <div class="treat_main_list" v-if="list2.length > 0">
                    <van-list
                        v-model="loading2"
                        :finished="finished2"
                        @load="onLoad"
                        :offset="30"
                        :immediate-check="false"
                        
                        finished-text="加载完成">
                        <div :class="{'content':true,'contentun' : item.isexpire == 1}" style="margin-bottom: 10px;" v-for="item in list2" >
                            <div class="top">
                                <div class="left">{{ item.cardname }}</div>
                                <div class="right">有效期至{{ item.effectivedate }}</div>
                            </div>
                            <div class="middle">
                                <div class="title title1">权益</div>
                                <div class="title">价值</div>
                                <div class="title">次数</div>
                            </div>
                            <div class="middle1" v-for="child in item.couponlist">
                                <div class="text textwidth">{{ child.couponname }}</div>
                                <div class="text">￥{{ child.money }}</div>
                                <div class="text">{{ child.totalnum }}（剩{{ child.surplusnum }}）</div>
                            </div>
                            <div class="tips" v-if="item.remark">
                                <div class="title">注意事项：</div>
                                <div class="info" style="white-space: break-spaces;">{{ item.remark }}</div>
                            </div>
                            <div class="btn" style="text-align: center;" v-if="item.isexpire != 1">
                                <div v-if="item.orderuserid == $route.query.userid">
                                    <div v-if="item.orderuserid == item.owneruserid">
                                        <div>于【 {{item.receivetime}} 】</div>
                                        <div>本人领取</div>
                                    </div>
                                    <div v-else>
                                        <div>于【 {{item.receivetime}} 】</div>
                                        <div>赠送给【{{item.ownerusername}}({{computedPhone(item.ownerphone)}})】</div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div>于【 {{item.receivetime}} 】</div>
                                    <div>【{{item.orderusername}}({{ computedPhone(item.orderphone)}} )】赠送</div>
                                    
                                </div>
                            </div>
                            
                            <div :class="{'ribbon':true,'ribbonun' : item.isexpire == 1}">{{item.isexpire == 1 ? '已过期' : '已领用'}}</div>
                        </div>
                    </van-list>
                </div>
                <div class="nodata" v-else>暂无卡包数据</div>

            </van-tab>
      
        </van-tabs>
    </div>
</template>
<script>
import {wxtriagecardnum,wxtriagecardqry,wxtriageeditcardreceive} from '@/api/medical'
import { mapGetters } from 'vuex'
import {Dialog,Toast} from 'vant'
export default{
    computed: {
        ...mapGetters([
            'username','dispname','clinicid'
        ]),
    },
    data(){
        return{
            qrystatus:'0',
            list2: [],
            // 无限加载
            loading2: false,
            finished2: false,
            list1: [],
            // 无限加载
            loading1: false,
            finished1: false,
            unuse: 0,
            used: 0,
            page:0,
        }
    },
    created(){
        this.$store.dispatch('hideOrShowNav', false)
        this.getcardnum()
        this.onLoad()
    },
    methods:{
        computedPhone(val){
            if(val){
               return val.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            }else{
                return ''
            }
            
        },
        receive(item){

            Dialog.confirm({
                title: '提示',
                message: `是否领用【${item.cardname}】卡包`,
                confirmButtonColor:'#CF8C57',
            }).then(() => {
                this.delcardreceive(item.id,item.orderuserid,'0')
            })
            .catch(() => {
                // on cancel
            });
            
            
        },
        give(item){
            this.$router.push({path:'/chooseUser',query:{
                uid:item.id,

            }})
        },
        delcardreceive(uid,userid,oprtype){
            let data = {
                username:this.username,
                uid,
                userid,
                oprtype,
            }
            wxtriageeditcardreceive(data).then(response =>{
         
                Toast({message:'领取卡包成功！',onClose:()=>{
                    location.reload()
                }})

            }).catch(err =>{
  
                // resolve(err)
                Toast({message:err.response_body.errorMsg,onClose:()=>{
                    location.reload()
                }})
            })
        },
        changeTab(){
            this.page = 0
            this.list1 = []
            this.loading1 = false
            this.finished1 = false
            this.list2 = []
            this.loading2 = false
            this.finished2 = false
            this.onLoad()
        },
        onLoad(){
           
            this.page += 1;
            if(this.qrystatus === '0' && this.finished1) {
                return
            }
            if(this.qrystatus === '1' && this.finished2) {
                return
            }

            let data = {
                username:this.username,
                qrypage:this.page,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
                qrytype:this.qrystatus
            }
            wxtriagecardqry(data).then(response =>{
                
                 if(this.qrystatus == '0'){
                    this.loading1 = false
                    if(response.response_body.count > 0){

                        this.list1 =[...this.list1, ...response.response_body.list] || []
                
                        if(this.page >= response.response_body.pages){
                            
                            this.finished1 = true
                        }
                    }else{
                        this.list1 = []
                        this.finished1 = true
                    }
     
                 }else if(this.qrystatus == '1'){
                    this.loading2 = false
                    if(response.response_body.count > 0){

                        this.list2 =[...this.list2, ...response.response_body.list] || []
                
                        if(this.page >= response.response_body.pages){
                            
                            this.finished2 = true
                        }
                    }else{
                        this.list2 = []
                        this.finished2 = true
                    }
                 }
            })

        },
        getcardnum(){
            let data = {
                username:this.username,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
            }
            wxtriagecardnum(data).then(response =>{
     
                this.unuse = response.response_body.nousenum
                this.used = response.response_body.usenum
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.coupon{
    .contentun{
        position: relative;
        padding:20px 10px;
        color:#b4b4b4!important;
        margin: auto;
        -webkit-mask: radial-gradient(circle at 15px, transparent 10px, red 0) -16px; 
        background: linear-gradient(45deg,#f4f0f0, #faf8f8) !important;
    }
    .content {
        border-radius: 5px;
        
        // width: 300px;
        // height: 130px;
        position: relative;
        padding:20px 10px;
        color: #fff;
        margin: auto;
        -webkit-mask: radial-gradient(circle at 0.4rem, transparent 0.26667rem, #ee7026 0) -0.42667rem;
        background: linear-gradient(45deg, #edab26, #f86e12);
        .top{
            display: flex;
            justify-content: space-between;
            align-items:center;
            .right{
                font-size:12px;
            }
            .left{
                font-size:14px;
            }
        }
        .middle{
            display: flex;
            justify-content: space-around;
            margin: 20px 0 0;
            text-align: center;
            .text{
                font-size: 12px;
                margin-top: 10px;
               
            }
            .title{
                width: 80px;
                text-align: center;
            }
            .title1{
                width: 40%;
            }
        }
        .middle1{
            display: flex;
            justify-content: space-around;
            
            text-align: center;
            .text{
                font-size: 12px;
                margin-top: 5px;
                width: 80px;
                white-space: nowrap;      
                overflow: hidden;         
                text-overflow: ellipsis;
            }
            .textwidth{
                width: 40%;
            }
        }
        .tips{
            margin-top: 20px;
            margin-left: 10px;
            margin-bottom: 20px;
        }
        .btn{
            display: flex;
            justify-content: space-evenly;
            margin-top:20px;
            .van-button--small{
                padding: 0 20px;
                color: #CF8C57;
            }
        }
        .ribbon {
            width: 90px;
            height: 20px;
            background: #ccc;
            position: absolute;
            right: -20px;
            bottom: 10px;
            transform: rotateZ(-36deg);
            text-align: center;
            color: #fff;
            font-size: 12px;
        }
        .ribbonun{
            color: #000;
        }
    }
    .treat_main_list{
        background-color: #fff;
    }
    .nodata{
        text-align: center;
        margin:80px 20px;
    }
}
</style>